<script setup lang="ts">
import { useRoute } from 'vue-router'
import { useCapitalAccountStore } from '../stores/capital-account-store'
import { ref, computed, onMounted, watch } from 'vue'
import TheLayout from '@/modules/shared/layouts/the-layout.vue'
import TheHeader from '../components/the-header.vue'
import TheNav from '../components/the-nav.vue'
import { VSection, VButton, VIcon, VButtonInvisible, VProgressBar, VSkeletonBar } from '@/modules/shared/components'
import {
  generateBookValue,
  generateSinceInceptionBookValue,
  generateYearToDateBookValue,
} from '../utils/use-capital-account'
import { useExtendedI18n } from '@/i18n'
import { capitalize } from 'lodash'
import { times, toNumber, difference } from '@/modules/shared/utils/money'
import { useEntityStore } from '../stores/entity-store'
import { useInvestorStore } from '@/modules/entity/stores/investor-store'
import { getChartData, transformEntity } from '../utils/entity'

const { n, t } = useExtendedI18n()
const route = useRoute()
const skeleton = ref(true)
const capitalAccountStore = useCapitalAccountStore()
const entityStore = useEntityStore()
const investorStore = useInvestorStore()
const currentEntity = ref(null)

const cid = computed(() => `${route.params.entity_type}:${route.params.entity_id}`)
const transfers = computed(() =>
  capitalAccountStore.transfers.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()),
)
// TODO: filter transfers by investors
const filteredTransfers = computed(() => transfers.value)
const yearOptions = computed(() => {
  let startYear = new Date().getFullYear()
  let endYear = new Date().getFullYear()

  if (transfers.value.length > 0) {
    startYear = new Date(transfers.value[0].date).getFullYear()
    endYear = new Date(transfers.value[transfers.value.length - 1].date).getFullYear()
  }

  return { startYear, endYear }
})
const bookValue = computed(() => generateBookValue(filteredTransfers.value, yearOptions.value))
const sinceInceptionBookValue = computed(() => generateSinceInceptionBookValue(bookValue.value))
const yearToDateBookValue = computed(() => generateYearToDateBookValue(bookValue.value))

////////////////////////////////////////
//// CHARTS
////////////////////////////////////////
const chartData = computed(() => {
  const entities = currentEntity.value
    ? transformEntity([currentEntity.value] || [], cid.value, true, investorStore.state.selectedKeys)
    : []
  return getChartData(entities, true, investorStore.state.selectedKeys)
})
const called_bar_ratio = computed(() => {
  const called = toNumber(chartData.value.called_received)
  const committed = toNumber(chartData.value.committed)
  return called / committed
})
const distribution_bar_ratio = computed(() => {
  const distributed = toNumber(chartData.value.distribution_distributed_capital)
  const hurdle = toNumber(chartData.value.hurdle)
  return distributed / hurdle
})

////////////////////////////////////////
//// TABLE CONFIG
////////////////////////////////////////
const bookValueYears = computed(() => Object.keys(bookValue.value).sort((a, b) => Number(b) - Number(a)))
const bookValueQuarters = ['Q4', 'Q3', 'Q2', 'Q1']
const rows = [
  {
    label: capitalize(t('shared.beginning balance')),
    key: 'beginning_balance',
  },
  {
    label: capitalize(t('shared.contribution', 0)),
    key: 'called_capital',
    break: true,
  },
  {
    label: capitalize(t('shared.distribution', 0)),
    key: 'distributed_capital',
  },
  {
    label: 'Total cash / deemed flows',
    key: 'total_cash',
  },
  {
    label: capitalize(t('shared.management fee', 0)),
    key: 'called_management_fees',
    break: true,
  },
  {
    label: capitalize(t('shared.other called fee', 0)),
    key: 'called_other_fees',
  },
  {
    label: capitalize(t('shared.carried interest')),
    key: 'distributed_carried_interest',
  },
  {
    label: capitalize(t('shared.other distributed fee', 0)),
    key: 'distributed_other_fees',
  },
  {
    label: capitalize(t('shared.ending balance')),
    key: 'ending_balance',
    break: true,
  },
]
const yearColumns = ref(
  Object.keys(bookValue.value).reduce((acc, year) => {
    acc[year] = true
    return acc
  }, {}),
)
const formatMoney = (money) => {
  const money_number = toNumber(money)
  if (money_number < 0) return `(${n(times(money, -1), 'currency')})`
  return n(money, 'currency')
}
watch(bookValue, (v) => {
  if (v) {
    yearColumns.value = bookValueYears.value.reduce((acc, year, i) => {
      acc[year] = i == 0
      return acc
    }, {})
  }
})

onMounted(async () => {
  await investorStore.reset()
  await Promise.all([
    entityStore.fetchEntity({
      type: route.params.entity_type as string,
      id: route.params.entity_id as string,
    }),
    capitalAccountStore.fetchTransfers(route.params.entity_type, route.params.entity_id),
    investorStore.listProfiles(),
  ])

  currentEntity.value = entityStore.items.get(`${route.params.entity_type}:${route.params.entity_id}`)
  skeleton.value = false
})
</script>

<template>
  <TheLayout>
    <TheHeader />
    <TheNav />
    <div class="rounded border-[1px] border-gray-300">
      <div class="border-b-[1px] border-gray-300 p-3">
        <div class="flex items-center justify-between">
          <div class="">
            <div class="font-semibold text-gray-500">{{ capitalize(t('shared.called')) }}</div>
            <VSkeletonBar v-if="skeleton" />
            <div class="text-2xl font-semibold" v-else>{{ formatMoney(chartData.called_received) }}</div>
          </div>
          <div class="">
            <div class="text-right font-semibold text-gray-500">{{ capitalize(t('shared.remaining')) }}</div>
            <VSkeletonBar v-if="skeleton" />
            <div class="text-2xl font-semibold" v-else>{{ formatMoney(chartData.committed_remaining) }}</div>
          </div>
        </div>
        <VSkeletonBar v-if="skeleton" class="my-3 !w-full" />
        <VProgressBar class="mt-3" color="v-blue" :progress="called_bar_ratio" v-else />
        <div class="">
          <div class="flex items-center gap-1">
            <div class="font-semibold">{{ capitalize(t('shared.capital')) }}:</div>
            <VSkeletonBar v-if="skeleton" />
            <div class="font-semibold" v-else>{{ formatMoney(chartData.called_received_capital) }}</div>
          </div>
          <div class="flex items-center gap-1">
            <div class="font-semibold">{{ capitalize(t('shared.management fee', 0)) }}:</div>
            <VSkeletonBar v-if="skeleton" />
            <div class="font-semibold" v-else>{{ formatMoney(chartData.called_received_management_fees) }}</div>
          </div>
          <div class="flex items-center gap-1">
            <div class="font-semibold">{{ capitalize(t('shared.other fee', 0)) }}:</div>
            <VSkeletonBar v-if="skeleton" />
            <div class="font-semibold" v-else>{{ formatMoney(chartData.called_received_other_fees) }}</div>
          </div>
        </div>
      </div>
      <div class="p-3">
        <div class="flex items-center justify-between">
          <div class="">
            <div class="font-semibold text-gray-500">{{ capitalize(t('shared.distributed to investor', 0)) }}</div>
            <VSkeletonBar v-if="skeleton" />
            <div class="text-2xl font-semibold" v-else>
              {{ formatMoney(chartData.distribution_distributed_capital) }}
            </div>
          </div>
          <div class="">
            <div class="text-right font-semibold text-gray-500">{{ capitalize(t('shared.until hurdle')) }}</div>
            <VSkeletonBar v-if="skeleton" />
            <div class="text-2xl font-semibold" v-else>{{ formatMoney(chartData.distribution_remaining) }}</div>
          </div>
        </div>
        <VSkeletonBar v-if="skeleton" class="my-3 !w-full" />
        <VProgressBar class="mt-3" color="v-blue" :progress="distribution_bar_ratio" v-else />
        <div class="">
          <div class="flex items-center gap-1">
            <div class="font-semibold">{{ capitalize(t('shared.capital')) }}:</div>
            <VSkeletonBar v-if="skeleton" />
            <div class="font-semibold" v-else>{{ formatMoney(chartData.distribution_distributed_capital) }}</div>
          </div>
          <div class="flex items-center gap-1">
            <div class="font-semibold">{{ capitalize(t('shared.carried interest')) }}:</div>
            <VSkeletonBar v-if="skeleton" />
            <div class="font-semibold" v-else>{{ formatMoney(chartData.distributed_carried_interest) }}</div>
          </div>
          <div class="flex items-center gap-1">
            <div class="font-semibold">{{ capitalize(t('shared.other fee', 0)) }}:</div>
            <VSkeletonBar v-if="skeleton" />
            <div class="font-semibold" v-else>{{ formatMoney(chartData.distributed_other_fees) }}</div>
          </div>
        </div>
      </div>
    </div>
    <VSection label="Capital Account" class="mt-12">
      <div class="relative w-full overflow-x-auto pb-5">
        <table class="w-full border-separate border-spacing-0">
          <thead>
            <tr>
              <td></td>
              <template v-for="(year, i) in bookValueYears">
                <template v-if="yearColumns[year]">
                  <td>
                    <div class="font-bold">{{ year }}</div>
                    <div class="text-sm text-gray-500">Year {{ bookValueYears.length - i }}</div>
                  </td>
                  <template v-for="(quarter, i) in bookValueQuarters">
                    <td>
                      <div class="flex justify-between">
                        <div class="font-bold">{{ quarter }}</div>
                        <VButton
                          variant="v-blue"
                          class="rotate-180 stroke-white"
                          :click="() => (yearColumns[year] = false)"
                          v-if="i === 3"
                        >
                          <VIcon name="arrow_right_with_bar" />
                        </VButton>
                      </div>
                    </td>
                  </template>
                </template>
                <td v-else class="close-column">
                  <div class="flex flex-col items-center gap-5">
                    <VButton variant="v-blue" class="stroke-white" :click="() => (yearColumns[year] = true)">
                      <VIcon name="arrow_right_with_bar" />
                    </VButton>
                    <div class="font-bold" style="writing-mode: vertical-lr">
                      {{ year }}
                    </div>
                  </div>
                </td>
              </template>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in rows">
              <td :class="{ break: row.break }">{{ row.label }}</td>
              <template v-for="(year, i) in bookValueYears">
                <template v-if="yearColumns[year]">
                  <td :class="{ break: row.break }">
                    <template v-if="['beginning_balance', 'ending_balance'].includes(row.key)">
                      {{ formatMoney(bookValue[year][row.key]) }}
                    </template>
                    <template v-else>
                      {{ formatMoney(bookValue[year]['transaction_summary'][row.key]) }}
                    </template>
                  </td>
                  <template v-for="quarter in bookValueQuarters">
                    <td :class="{ break: row.break }">
                      <template v-if="['beginning_balance', 'ending_balance'].includes(row.key)">
                        {{ formatMoney(bookValue[year][quarter][row.key]) }}
                      </template>
                      <template v-else>
                        {{ formatMoney(bookValue[year][quarter]['transaction_summary'][row.key]) }}
                      </template>
                    </td>
                  </template>
                </template>
                <td v-else class="close-column"></td>
              </template>
            </tr>
          </tbody>
        </table>
      </div>
    </VSection>
  </TheLayout>
</template>

<style scoped>
table thead tr td:nth-child(1),
table tbody tr td:nth-child(1) {
  @apply sticky left-0 z-10 w-[250px] min-w-[250px] max-w-[250px] bg-white !text-left;
}
table thead tr td {
  @apply h-[100px] border-t-[1px] border-gray-300 align-top;
}
table thead tr td:nth-child(1) {
  @apply !border-t-0;
}
table tbody tr td {
  @apply border-b-[1px] border-gray-300 text-right align-top;
}
table tr td {
  @apply min-w-[150px] border-r-[1px] border-gray-300 px-2 py-2;
}
table tr td.break {
  @apply !pt-10;
}
table tr td.close-column {
  @apply !w-[1%] !min-w-[50px] bg-gray-200;
}
</style>
