import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useFetch } from '@/modules/shared/composables/use-fetch'
import { addItem, addItems, clearItems } from '@/modules/shared/utils/store'
import { CID } from '@/modules/shared/utils/store.types'
import { Money } from '@/modules/shared/utils/money'

///////////////////////////////////////////////////////////////////////////////
// Types
///////////////////////////////////////////////////////////////////////////////

type ID = number | string

type Transfer = {
  id: string
  date: string
  type: 'CapitalCallTransfer' | 'DisbursementTransfer'
  transfer_type: 'cash' | 'asset' | 'recycled'
  called_capital: Money
  called_management_fees: Money
  called_other_fees: Money
  total_called: Money
  distributed_capital: Money
  distributed_return_of_capital: Money
  distributed_profit: Money
  distributed_carried_interest: Money
  distributed_other_fees: Money
  total_distributed: Money
  entity: {
    id: string
  }
  investor: {
    id: string
  }
}

///////////////////////////////////////////////////////////////////////////////
// Store
///////////////////////////////////////////////////////////////////////////////

type TransferMap = Map<CID, Transfer>

export const useCapitalAccountStore = defineStore('investing/capitalAccountStore', () => {
  const route = useRoute()
  const baseUrl = computed(() => `/${route.params.slug}/investing`)

  const items = ref<TransferMap>(new Map())
  const transfers = computed(() => Array.from(items.value.keys()).map((key) => items.value.get(key)))

  const fetchTransfers = async (investable_type, investable_id) => {
    const { data, error } = await useFetch(`${baseUrl.value}/${investable_type}/${investable_id}/capital_accounts`)
      .get()
      .json<{ data: Transfer[] }>()

    if (error.value) {
      // TODO: handle error (e.g., display a message to the user)
      console.error(error.value)
      return
    }

    clearItems(items)
    addItems(
      items,
      data.value.data.map((transfer) => ({ ...transfer, _cid: transfer.id })),
    )
  }

  return {
    items,
    transfers,

    fetchTransfers,
  }
})
